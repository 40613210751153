import fetchApi from '../helpers/fetch-api';
import sliceText from '../helpers/sliceText';

const blockPosts = (Swiper, Autoplay) => {
	const block = '.js-swiper-posts';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	const dataSpeed = blockContainer.getAttribute('data-speed');

	let dataApiUrl = blockContainer.getAttribute('data-api-url');

	if (!dataApiUrl) {
		return false;
	}

	let dataPostsCount = blockContainer.getAttribute('data-posts-count');

	if (!dataPostsCount) {
		dataPostsCount = 6;
	}

	let dataPostsCharts = blockContainer.getAttribute('data-posts-charts');
	if (!dataPostsCharts) dataPostsCharts = 200;

	dataApiUrl = `${dataApiUrl}&per_page=${dataPostsCount}`;

	let html = '';
	const wrapper = document.querySelector('.b-posts__wrapper');

	// eslint-disable-next-line no-undef
	fetchApi('GET', dataApiUrl).then((response) => {
		// console.log(response.data);
		response.forEach((post) => {
			html += '<div class="swiper-slide">';
			html += '<article class="b-posts__preview">';
			if (post.fimg_url) {
				html += post.fimg_url && `<img src="${post.fimg_url}" class="attachment-large size-large" alt="">`;
			}
			html += '<div class="b-posts__content">';
			html += '<h3 class="b-posts__title fw-semibold">';
			if (post.title) {
				html += post.title.rendered;
			}
			html += '</h3>';
			html += '<div class="b-posts__excerpt fw-normal">';
			if (post.excerpt) {
				if (post.excerpt.rendered) html += `<p>${sliceText(post.excerpt.rendered, dataPostsCharts)}</p>`;
			}
			html += '</div>';
			if (post.link) {
				html +=
					post.link &&
					`<a href="${post.link}" class="c-btn c-btn--secondary b-posts__more c-btn--icon pl-0">`;
			}
			html += 'Czytaj więcej';
			html +=
				'<span><svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">';
			html +=
				'<path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.99997C0 5.80106 0.0790178 5.61029 0.21967 5.46964C0.360322 5.32899 0.551088 5.24997 0.75 5.24997H9.4395L6.219 2.03097C6.07817 1.89014 5.99905 1.69913 5.99905 1.49997C5.99905 1.30081 6.07817 1.1098 6.219 0.968971C6.35983 0.828141 6.55084 0.749023 6.75 0.749023C6.94916 0.749023 7.14017 0.828141 7.281 0.968971L11.781 5.46897C11.8508 5.53864 11.9063 5.6214 11.9441 5.71252C11.9819 5.80364 12.0013 5.90132 12.0013 5.99997C12.0013 6.09862 11.9819 6.1963 11.9441 6.28742C11.9063 6.37854 11.8508 6.4613 11.781 6.53097L7.281 11.031C7.14017 11.1718 6.94916 11.2509 6.75 11.2509C6.55084 11.2509 6.35983 11.1718 6.219 11.031C6.07817 10.8901 5.99905 10.6991 5.99905 10.5C5.99905 10.3008 6.07817 10.1098 6.219 9.96897L9.4395 6.74997H0.75C0.551088 6.74997 0.360322 6.67095 0.21967 6.5303C0.0790178 6.38965 0 6.19888 0 5.99997Z" fill="#ED2369"></path>';
			html += '</svg>';
			html += '</span>';
			html += '</a>';
			html += '</div>';
			html += '</article>';
			html += '</div>';
		});
		wrapper.innerHTML = html;
	});

	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		centeredSlides: false,
		navigation: false,
		autoplay: {
			delay: dataSpeed,
		},
		slidesPerView: 1,
		autoHeight: false,
		spaceBetween: 30,
		loop: false,
		breakpoints: {
			992: {
				slidesPerView: 3,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockPosts;
