import fetchApi from '../helpers/fetch-api';
import createSlug from '../helpers/createSlug';

const renderDataShorts = (box, queue, block, queueSlug, last) => {
	const responseContent = block.querySelector('.js-results');
	const loader = block.querySelector('.js-loader');

	if (responseContent && block && box) {
		const serviceName = box.getAttribute('data-serviceName');
		const idService = box.getAttribute('data-serviceId');
		const slugService = serviceName ? createSlug(serviceName) : '';
		const basicUrl = responseContent.getAttribute('data-orginalUrl');

		// containers
		const daysNumCont = box.querySelector('.js-days-num');
		const daysTextCont = box.querySelector('.js-text-days');
		const btnLinkCont = box.querySelector('.js-btn-link');
		const cityNameCont = box.querySelector('.js-city-name');

		fetchApi(
			'GET',
			// eslint-disable-next-line no-undef
			`${appVariables.apiUrl}/api/v1/detailed-facilities?queueId=${queue}&serviceId=${idService}&sortBy=daysUntilExamination&sortOrder=ASC&limit=4`,
		).then((response) => {
			if (response.data && response.data.length > 0) {
				if (response.data) {
					let mainDays = 0;
					const allFacialities = [];

					response.data.forEach((data, key) => {
						if (data?.facilityServiceSummary?.daysUntilExamination) {
							const daysToExamination = Number(data.facilityServiceSummary.daysUntilExamination);

							if (key === 0) {
								mainDays = daysToExamination;
							}

							if (mainDays === daysToExamination) {
								allFacialities.push(data);
							}
						}
					});

					// random chose facialities and render html
					if (allFacialities && Array.isArray(allFacialities)) {
						const indRandom = Math.floor(Math.random() * allFacialities.length);
						const facialitiMainData = allFacialities[indRandom];

						if (facialitiMainData) {
							const facialityDays = facialitiMainData?.facilityServiceSummary?.daysUntilExamination;
							const facialityCity = facialitiMainData?.facility?.city?.name;
							const facialityProvince = facialitiMainData?.facility?.province?.name;
							let linkBtn = `${basicUrl + slugService}/`;

							if (facialityProvince) {
								linkBtn += `${createSlug(facialityProvince)}/`;
							}

							if (facialityCity) {
								linkBtn += `${createSlug(facialityCity)}/`;
							}

							if (queueSlug) {
								linkBtn += queueSlug;
							}

							linkBtn += '?sortBy=daysUntilExamination&sortOrder=ASC';

							if (facialityDays && daysNumCont && daysTextCont) {
								const textDay = facialityDays === 1 ? 'dzień' : 'dni';
								daysNumCont.textContent = facialityDays;
								daysTextCont.textContent = textDay;
							}

							if (facialityCity && cityNameCont) {
								cityNameCont.textContent = facialityCity;
							}

							if (linkBtn && btnLinkCont) {
								btnLinkCont.href = linkBtn;
							}

							if (last) {
								if (loader) loader.style.display = 'none';
							}
						}
					}
				}
			}
		});
	}
};
const fetchShortestData = (queue = 1, block, queueSlug) => {
	const singleBoxes = block.querySelectorAll('.js-box');

	if (singleBoxes) {
		singleBoxes.forEach((box, index) => {
			const last = index + 1 === singleBoxes.length;
			renderDataShorts(box, queue, block, queueSlug, last);
		});
	}
};

const blockShortestQueue = () => {
	const blockShortest = document.querySelector('.js-shortest-queue-block');

	if (blockShortest) {
		const shortsInputs = blockShortest.querySelectorAll('.js-shortest-que-radio');
		const loader = blockShortest.querySelector('.js-loader');

		if (shortsInputs.length > 0) {
			fetchShortestData(1, blockShortest, 'kolejka-normalna', false);

			shortsInputs.forEach((el) => {
				el.addEventListener('change', (e) => {
					if (loader) loader.style.display = 'flex';
					const queueSlug = e.currentTarget.getAttribute('data-slug');
					const elVal = e.currentTarget.value;
					fetchShortestData(elVal, blockShortest, queueSlug, true);
				});
			});
		}
	}
};

export default blockShortestQueue;
