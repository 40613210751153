import fetchApi from '../helpers/fetch-api';
import createSlug from '../helpers/createSlug';
import changesUrlsFormsGet from '../helpers/changeUrlforGet';
// import slugify from '../../../../../../../../node_modules/slugify';

const fetchCities = (chosenValue, cities) => {
	// eslint-disable-next-line no-undef
	fetchApi('GET', `${appVariables.apiUrl}/api/v1/cities?limit=500&provinceId=${chosenValue}`).then((response) => {
		if (response.data) {
			if (response.data.length > 0) {
				response.data.forEach((provinceData) => {
					const option = document.createElement('option');
					const slugProvince = createSlug(provinceData.name);
					if (slugProvince) option.setAttribute('data-slug', slugProvince);
					option.text = provinceData.name;
					option.value = provinceData.id;
					cities.appendChild(option);
				});
			}
		}
	});
};
const blockSearch = () => {
	/**
	 *
	 * Przekierowanie w wyszukiwarce:
	 * /app/results/service/serviceId/queue/queueId/province/provinceId/city/cityId
	 * Czyli kierujemy na:
	 * /app/results/rezonans/1/normalna/1/dolnoslaskie/1/warszawa/10/
	 *
	 */
	const service = document.getElementById('service');
	let onlyOne = true;
	const asapSelect = document.getElementById('asap-select');
	const formGet = document.querySelector('.js-form-search-spec-get');

	if (formGet) {
		changesUrlsFormsGet(formGet);
	}

	if (service) {
		// detect change service and rewrite base link
		service.addEventListener('change', (e) => {
			const optionVal = e.currentTarget.value;
			const formSearch = service.closest('form');

			if (formSearch && optionVal) {
				const urlMain = formSearch.getAttribute('data-orginalUrl');
				const urlSec = formSearch.getAttribute('data-secondUrl');

				if (urlMain && urlSec) {
					if (optionVal === 'all') {
						formSearch.setAttribute('action', urlSec);

						if (onlyOne && asapSelect) {
							const optAll = asapSelect.querySelector('.all-js');
							if (optAll) {
								asapSelect.selectedIndex = 2;
								asapSelect.value = optAll.value;
								onlyOne = false;
							}
						}
					} else {
						formSearch.setAttribute('action', urlMain);
					}
				}
			}
		});

		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/services?active=1`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((serviceData) => {
						const slugService = createSlug(serviceData.name);
						const option = document.createElement('option');
						option.text = serviceData.name;
						option.value = serviceData.id;
						if (slugService) option.setAttribute('data-slug', slugService);
						service.appendChild(option);
					});

					const allVal = service.getAttribute('data-optionAllVal');
					const allName = service.getAttribute('data-optionAllName');

					if (allVal && allName) {
						const optionAll = document.createElement('option');
						optionAll.text = allName;
						optionAll.value = allVal;
						if (optionAll) service.appendChild(optionAll);
					}
				}
			}
		});
	}

	const asap = document.getElementById('asap');
	if (asap) {
		asap.addEventListener('change', (e) => {
			const asap2 = document.getElementById('asap-2');
			if (e.currentTarget.checked) {
				e.currentTarget.value = 2;
				asap2.disabled = true;
			} else {
				asap2.disabled = false;
			}
		});
	}

	const province = document.getElementById('province');

	if (province) {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/provinces`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((provinceData) => {
						const option = document.createElement('option');
						const slugProvince = createSlug(provinceData.name);
						if (slugProvince) option.setAttribute('data-slug', slugProvince);
						option.text = provinceData.name;
						option.value = provinceData.id;
						province.appendChild(option);
					});
				}
			}
		});
	}

	const cities = document.getElementById('cities');

	if (cities && province) {
		// if (province.options.length > 0) {
		// 	fetchCities(province.options[0], cities); // inicjalizacja miast z pierwszego województwa
		// }
		province.addEventListener('change', (e) => {
			const currentOptions = cities.querySelectorAll('option');
			if (currentOptions.length > 0) {
				currentOptions.forEach((option) => {
					option.remove();
				});
				const option = document.createElement('option');
				option.text = 'wszystkie';
				option.value = '';
				cities.appendChild(option);
			}
			const chosenValue = e.currentTarget.options[e.currentTarget.selectedIndex].value;
			fetchCities(chosenValue, cities);
		});
	}
};

export default blockSearch;
