const changesUrlsFormsGet = (form) => {
	form.addEventListener('submit', (e) => {
		e.preventDefault();

		const formActionUrl = form.getAttribute('action');
		const selects = form.querySelectorAll('select');
		const slugsArrOrderId = ['service', 'province', 'cities', 'asap-select'];
		const slugsArr = [];

		if (selects) {
			selects.forEach((select) => {
				const markerId = select.id;
				const selectedOption = select.selectedOptions[0];

				if (selectedOption) {
					const slugOpt = selectedOption.getAttribute('data-slug');
					const index = slugsArrOrderId.indexOf(markerId);
					if (index >= 0 && slugOpt) slugsArr[index] = slugOpt;
				}
			});
		}

		if (slugsArr) {
			const cleanedSlugsArr = slugsArr.filter((slug) => slug && slug.trim() !== '');
			const slugString = cleanedSlugsArr.join('/');

			const actionFilteredUrl = `${formActionUrl + slugString}/`;

			window.location.href = actionFilteredUrl;
		}
	});
};

export default changesUrlsFormsGet;
