const createSlug = (name) => {
	const lettersPl = {
		ą: 'a',
		ć: 'c',
		ę: 'e',
		ł: 'l',
		ń: 'n',
		ó: 'o',
		ś: 's',
		ź: 'z',
		ż: 'z',
		Ą: 'a',
		Ć: 'c',
		Ę: 'e',
		Ł: 'l',
		Ń: 'n',
		Ó: 'o',
		Ś: 's',
		Ź: 'z',
		Ż: 'z',
	};

	const changeLetter = name.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, (letter) => lettersPl[letter.toLowerCase()] || letter);

	return changeLetter.toLowerCase().replace(/[\W_]+/g, '-');
};

export default createSlug;
