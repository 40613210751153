/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */

const anchormenu = () => {
	const parents = document.querySelectorAll('.js-wrapper-child-menu');
	const navMobileBtn = document.querySelector('.js-menu-template-child');
	const menuChildWrap = document.querySelector('.js-menu-child-wrap');

	const mobileMenu = () => {
		navMobileBtn.addEventListener('click', (e) => {
			const el = e.currentTarget;
			el.classList.toggle('active');
			if (el.previousElementSibling) el.previousElementSibling.classList.toggle('d-none');
			if (menuChildWrap) menuChildWrap.classList.toggle('active');
		});
	};

	const checkedActive = (menuLinks, s, type, baseUrl) => {
		const slugSe = s.getAttribute('data-slug');

		menuLinks.forEach((link) => {
			if (link.hash === `#${s.id}`) {
				if (type === 'add' && !link.classList.contains('is-active')) {
					link.classList.add('is-active');
					const title = s.getAttribute('data-childTitle');
					const newUrl = baseUrl + slugSe;
					window.history.pushState(null, null, newUrl);
					document.title = title;
				} else if (type === 'remove' && link.classList.contains('is-active')) {
					link.classList.remove('is-active');
				}
			}
		});
	};

	if (parents) {
		parents.forEach((cont) => {
			const menuLinks = cont.querySelectorAll('.js-nav-link');
			const sections = cont.querySelectorAll('.js-child-content');
			const baseUrl = cont.getAttribute('data-pageUrl');
			const defaultTitle = cont.getAttribute('data-defautlTitle');
			const childSlug = cont.getAttribute('data-firstscrollchild');

			window.addEventListener('scroll', () => {
				const fromTop = window.pageYOffset;
				const parentH = cont.getBoundingClientRect().top;

				if (parentH > 100) {
					// console.log(parentH);
					if (document.title !== defaultTitle) {
						document.title = defaultTitle;
					}
				}

				sections.forEach((s) => {
					if (s) {
						const relativePosition =
							s.getBoundingClientRect().top + document.documentElement.scrollTop - 95;

						if (relativePosition <= fromTop && relativePosition + s.offsetHeight > fromTop) {
							checkedActive(menuLinks, s, 'add', baseUrl);
						} else {
							checkedActive(menuLinks, s, 'remove', baseUrl);
						}
					}
				});
			});

			if (menuLinks) {
				menuLinks.forEach((link) => {
					link.addEventListener('click', (e) => {
						e.preventDefault();
						const idSe = e.currentTarget.getAttribute('data-target');

						if (idSe) {
							const se = document.getElementById(idSe);
							const additionalSpace = 90;
							const yOffset = window.scrollY + se.getBoundingClientRect().top - additionalSpace;
							window.scrollTo({ top: yOffset, behavior: 'smooth' });
						}
					});
				});
			}

			if (navMobileBtn) mobileMenu();

			// first scroll to current section (child page)
			if (childSlug) {
				sections.forEach((section) => {
					if (section.id === childSlug) {
						setTimeout(() => {
							const se = document.getElementById(section.id);
							const additionalSpace = 90;
							const yOffset = window.scrollY + se.getBoundingClientRect().top - additionalSpace;
							window.scrollTo({ top: yOffset, behavior: 'smooth' });
						}, 200);
					}
				});
			}
		});
	}
};

export default anchormenu;
