import fetchApi from '../helpers/fetch-api';
import formatedData from '../helpers/formatedData';
import renderStars from '../helpers/renderStars';
import createSlug from '../helpers/createSlug';

const blockfacilitiesNearby = () => {
	let defaultStateId = 7; // mazowieckie
	const locationData = JSON.parse(localStorage.getItem('locationData'));
	if (locationData) defaultStateId = locationData.provinceId;

	const radioInputs = document.querySelectorAll('.js-radio-input-placed');
	// eslint-disable-next-line no-undef
	const urlBaseAppDetails = appVariables.apiDetailsFacialities;

	const fetchFacialityReview = (idFaciality) => {
		let reviewFaciality = '';
		const urlGet = 'context=POSITIVE&status=ACCEPTED&limit=1';

		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/facilities/${idFaciality}/reviews?${urlGet}`).then(
			(response) => {
				if (response.data) {
					if (response.data.length > 0) {
						const revData = response.data[0];

						if (revData) {
							const { content, createdAt, name } = revData;
							const newFormatedDate = formatedData(createdAt);
							let newContent = '';

							if (content.length > 200) {
								newContent = `${content.substring(0, 200)}...`;
							} else {
								newContent = content;
							}

							const displayName = name.length > 0 ? name : 'Pacjent';

							reviewFaciality += "<div class='c-review-simple'>";
							reviewFaciality += "<div class='c-review-simple__header'>";
							reviewFaciality += `<span class='c-review-simple__header-name'>${displayName}</span>`;
							reviewFaciality += `<span class='c-review-simple__header-date'>${newFormatedDate}</span>`;
							reviewFaciality += '</div>';
							reviewFaciality += `<div class='c-review-simple__content'>${newContent}</div>`;
							reviewFaciality += '</div>';
						}

						if (reviewFaciality) {
							const renderedRev = document.getElementById(`revFaciality-${idFaciality}`);
							if (renderedRev) renderedRev.innerHTML = reviewFaciality;
						}
					}
				}
			},
		);
	};

	const renderBoxFacialities = (faciality) => {
		let facialityBox = '';

		const { name, id, street, zipCode, city } = faciality.facility;
		const { totalReviews, rating } = faciality;

		facialityBox += "<div class='col-lg-4 col-md-6 b-facilities-nearby__single-col'>";
		facialityBox += "<div class='c-box-single-facialitie'>";
		facialityBox += "<div class='c-box-single-facialitie__segment c-box-single-facialitie__segment--header'>";
		facialityBox += `<h4 class='c-box-single-facialitie__name'><a class="c-link c-link--underline-disable" href="${urlBaseAppDetails}/${createSlug(
			name,
		)}/${id}">${name}</a></h4>`;
		facialityBox += '</div>';
		facialityBox += "<div class='c-box-single-facialitie__segment c-box-single-facialitie__segment--street'>";
		facialityBox += `<div class='c-box-single-facialitie__adress'>ul. <span class='c-box-single-facialitie__adress-street'>${street.toLowerCase()}</span>, <br> ${zipCode} ${
			city.name
		}</div>`;
		facialityBox += '</div>';
		facialityBox += "<div class='c-box-single-facialitie__segment c-rating-value c-rating-value--between'>";
		facialityBox += `<div class='c-rating-value__stars'>${renderStars(rating)}</div>`;
		facialityBox += "<div class='c-rating-value__additional-wrap'>";
		facialityBox += `<div class='c-rating-value__rating'>${rating.toFixed(2)} / 5</div>`;
		facialityBox += `<div class='c-rating-value__rating-count'>(${totalReviews} ocen)</div>`;
		facialityBox += '</div>';
		facialityBox += '</div>';
		facialityBox += `<div class='c-box-single-facialitie__segment c-box-single-facialitie__segment--review  js-facialitie-rev' id="revFaciality-${id}"> </div>`;
		facialityBox += '</div>';
		facialityBox += '</div>';

		return facialityBox;
	};

	const fetchDataReview = (orderBy, provId = '') => {
		let facialitiesBox = '';
		if (provId.length > 0) defaultStateId = provId;

		const wrapper = document.querySelector('.js-facialities-nearby-result');
		const parameters = `sortBy=${orderBy}&provinceId=${defaultStateId}&sortOrder=DESC&limit=3&queueId=1`;

		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/detailed-facilities?${parameters}`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					console.log(response.data);

					response.data.forEach((facialitiesData) => {
						facialitiesBox += renderBoxFacialities(facialitiesData);
						fetchFacialityReview(facialitiesData.facility.id);
					});

					wrapper.innerHTML = facialitiesBox;
				}
			}
		});
	};

	const provinceInput = document.querySelector('.js-custom-select #province');

	if (radioInputs) {
		if (provinceInput) {
			provinceInput.addEventListener('change', () => {
				const { selectedIndex } = provinceInput;
				const provinceId = provinceInput.options[selectedIndex].value;

				radioInputs.forEach((input) => {
					if (input.checked) {
						const valinput = input.value;
						if (valinput) fetchDataReview(valinput, provinceId);
					}
				});
			});
		}

		radioInputs.forEach((input) => {
			// init action url
			if (input.checked) {
				const valinput = input.value;
				if (valinput) fetchDataReview(valinput);
			}

			input.addEventListener('change', (e) => {
				const { value } = e.currentTarget;
				const provinceInput2 = document.querySelector('.js-custom-select #province');
				const { selectedIndex } = provinceInput2;
				const provinceId = provinceInput2.options[selectedIndex].value;

				if (value && provinceId) fetchDataReview(value, provinceId);
				else if (value) fetchDataReview(value);
			});
		});
	}
};

export default blockfacilitiesNearby;
